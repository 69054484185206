import React from "react";
import {
  Button,
  Card,
  Header,
  Modal,
  Progress,
  Rating
} from "semantic-ui-react";

class ProgressSidebar extends React.Component {
  render() {
    const { translations, updateRating } = this.props;
    let ratingSum = 0;
    let ratingMax = 0;
    let translationNodeWrappers = Object.keys(translations).map(word => {
      const { translation, rating } = translations[word];
      ratingSum += rating;
      ratingMax += 5;
      return {
        rating,
        nodes: (
          <Card key={word} fluid>
            <Card.Content>
              <Card.Header>{translation}</Card.Header>
              <Card.Meta>English: {word}</Card.Meta>
              <Card.Description>
                Definition: Lorem ipsum dolor sit amet, consectetur adipiscing
                elit. Donec sed augue quis nisi commodo pulvinar.
              </Card.Description>
            </Card.Content>
            <Card.Content extra>
              Difficulty:{" "}
              <Rating
                icon="star"
                defaultRating={rating}
                maxRating={5}
                onRate={(event, data) =>
                  updateRating(word, translations[word], data.rating)
                }
              />
            </Card.Content>
          </Card>
        )
      };
    });
    const translationNodes = translationNodeWrappers
      .sort((a, b) => b.rating - a.rating) // Sort high to low
      .map(x => x.nodes);
    const numTerms = translationNodeWrappers.length;
    return (
      <div>
        <Header as="h2">
          Progress{" "}
          {numTerms === 0
            ? ""
            : `(${numTerms} Term${numTerms === 1 ? "" : "s"})`}
        </Header>
        <Card.Meta>Sorted by difficulty (highest to lowest)</Card.Meta>
        <hr />
        <div className="progress-translation-cards">
          {translationNodes.length === 0 ? (
            <i>
              Words you have starred will appear here! Hover over a translated
              word and star it based on your understanding.
            </i>
          ) : (
            <React.Fragment>
              <Card.Group>{translationNodes}</Card.Group>
              <Modal
                trigger={
                  <Button
                    secondary
                    disabled={numTerms === 0}
                    style={{ marginTop: 12 }}
                  >
                    Export Terms
                  </Button>
                }
                size="small"
                header="Feature Not Yet Implemented!"
                content="This is just a proof of concept. Integrations with classroom curriculum, Quizlet, progress dashboards, teacher analytics, etc. are expected in the full release in Spring 2020."
                actions={[{ key: "done", content: "Done", positive: true }]}
              />
            </React.Fragment>
          )}
        </div>

        <div class="progress-bottom">
          <Progress
            value={ratingMax === 0 ? 1 : ratingMax - ratingSum}
            total={ratingMax === 0 ? 1 : ratingMax}
            progress={ratingMax === 0 ? "" : "percentage"}
            indicating
          >
            {ratingMax === 0 ? "All Caught Up!" : "Progress"}
          </Progress>
        </div>
      </div>
    );
  }
}

export default ProgressSidebar;
