import React from "react";
import "./index.css";
import Progress from "./progress";
import Reader from "./reader";

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      article: {
        isLoading: true,
        didError: false,
        error: "",
        translations: [],
        article: []
      },
      progress: {
        translations: {} // Bucket for progress sidebar
      }
    };

    this.getArticle();
  }

  updateRating = (word, translationObject, newRating) => {
    const {
      article: { translations },
      progress: { translations: translationsBucket }
    } = this.state;
    const { translation } = translationObject;

    // Update master list of translations
    const updatedMasterTranslations = { ...translations };
    updatedMasterTranslations[word].rating = newRating;

    // Update translation bucket
    const updatedTranslations = { ...translationsBucket };
    if (newRating === 0) {
      delete updatedTranslations[word];
    } else {
      updatedTranslations[word] = {
        translation,
        rating: newRating
      };
    }
    this.setState({
      progress: {
        translations: updatedTranslations
      }
    });
  };

  getArticle() {
    const { article } = this.state;
    fetch("/translated_article.json")
      .then(response => response.json())
      .then(jsonData => {
        const translationsWithRatings = { ...jsonData["translations"] };
        Object.keys(translationsWithRatings).forEach(key => {
          translationsWithRatings[key] = {
            translation: translationsWithRatings[key],
            rating: 0 // All start at 0
          };
        });
        this.setState({
          article: {
            ...article,
            isLoading: false,
            translations: translationsWithRatings,
            article: jsonData["article"]
          }
        });
      })
      .catch(error => {
        console.error(error);
        this.setState({
          article: {
            ...article,
            didError: true,
            error
          }
        });
      });
  }

  render() {
    const { article, translations, progress } = this.state;
    return (
      <div>
        <div className="home-reader">
          <Reader
            article={article}
            translations={translations}
            updateRating={this.updateRating}
          />
        </div>
        <div className="home-progress">
          <Progress
            translations={progress.translations}
            updateRating={this.updateRating}
          />
        </div>
      </div>
    );
  }
}

export default Home;
