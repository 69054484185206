import React from "react";
import { Header, Loader } from "semantic-ui-react";
import Paragraph from "./paragraph";

class Reader extends React.Component {
  getChunks = (article, translations) => {
    const { updateRating } = this.props;
    return article.map((section, i) => {
      const { content, type } = section;
      if (type === "header") {
        return (
          <Header as="h2" key={i}>
            {content.join(" ")}
          </Header>
        );
      } else {
        return (
          <Paragraph
            key={i}
            paragraphId={i}
            words={content}
            translations={translations}
            updateRating={updateRating}
          />
        );
      }
    });
  };

  render() {
    const {
      article: { isLoading, article, translations }
    } = this.props;

    if (isLoading) return <Loader />;

    const { title, subtitle, author, content } = article;
    const chunks = this.getChunks(content, translations);

    return (
      <div className="home-reader-article">
        <Header as="h1">Syntext</Header>
        <hr />
        <Header as="h2">{title}</Header>
        <Header as="h3">{subtitle}</Header>
        <p>
          <strong>
            <i>By {author}</i>
          </strong>
        </p>
        <div className="home-reader-article-content">{chunks}</div>
      </div>
    );
  }
}

export default Reader;
