import React from "react";
import { Popup, Rating } from "semantic-ui-react";

class Paragraph extends React.Component {
  onRate = (word, translationObject, newRating) => {
    const { updateRating } = this.props;
    updateRating(word, translationObject, newRating);
  };
  render() {
    const { words, translations, paragraphId } = this.props;

    const paragraphNodes = words.map((word, i) => {
      if (word[0] === "~") {
        const strippedWord = word.substr(1);
        const translationObject = translations[strippedWord];
        const { translation, rating } = translationObject;
        return (
          <Popup
            key={`${paragraphId}-${i}`}
            trigger={
              <span className="translated-target">{`${translation} `}</span>
            }
            hoverable
          >
            <Popup.Header>{strippedWord}</Popup.Header>
            <Popup.Content>
              <Rating
                icon="star"
                defaultRating={rating}
                maxRating={5}
                onRate={(event, data) =>
                  this.onRate(strippedWord, translationObject, data.rating)
                }
              />
            </Popup.Content>
          </Popup>
        );
      } else {
        return `${word} `;
      }
    });

    return <p>{paragraphNodes}</p>;
  }
}

export default Paragraph;
